import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import DateFinder from "../Booking/DateFinder"

const MainBanner = () => {
    return (
        <div className="hero-banner main-banner-item yoga-girl">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 col-md-12">

                    </div>

                    <div className="col-lg-7 col-md-12">
                        <div className="hero-banner-content">

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Yoga at <br />LAMYALI FARMS</h1>
                            </ReactWOW>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Perfect Retreat resorts in the lap of Himalyas and on the banks of serene river.</p>
                            </ReactWOW>
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className='datefinder col-lg-9'>
                                    <DateFinder />
                                </div>
                            </ReactWOW>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default MainBanner